import React from 'react';
import NavBar from '../shared/navBar/NavBar';

const Blogs = () => {
    return (
                 <div>
                     <NavBar/>
                     <h3 className="mt-5 ms-5 mb-5 text-center">As a tech professional I strongly believe that knowledge sharing is the best resource <br/>
                     to make oneself updated with new skills. Keeping in mind that I always try to write my analytics on blog.<br/>
                      </h3>
                    <div  className="jumbotron jumbotron-fluid">
            <div style={{border : '1px solid black'}} className="container mt-5 mb-5 text-justify">
            <h3 style={{color : 'violet'}}>The story of 21st century how evolvs Tech..</h3>
            <p className="lead">I am expressing my keen interest and I know I will be well-suited for the job. Last few months I have developed a few stunning and sophisticated websites for local start-ups as well as international exposure. I rely on extensive knowledge of MERN stacks along with Redux, Google Firebase, Heroku, and Git and Github as a version control system. I have strong analytical knowledge that I regularly put into practice through testing in software development. My strong capabilities with visually stunning web pages make me a valuable team member. I also possess strong interpersonal skills and readily interact with clients and co-workers to achieve desired results. I focus on time management to accomplish the task within the stipulated time.
            I strongly believe you will be impressed seeing the mentioned skill sets of any of your employees. I anticipate meeting with you soon to discuss my potential candidacy and what I have to offer. I have forwarded my resumes and websites..</p>
            <a href="https://rhasan346.medium.com/journey-to-advance-react-ef8ecac8c054">Read more</a>
             </div>
             </div>
             <div  className="jumbotron jumbotron-fluid">
            <div style={{border : '1px solid black'}} className="container mt-5 mb-5">
            <h3 style={{color : 'lawngreen'}}>Once upon a time....</h3>
            <p className="lead">I am expressing my keen interest and I know I will be well-suited for the job. Last few months I have developed a few stunning and sophisticated websites for local start-ups as well as international exposure. I rely on extensive knowledge of MERN stacks along with Redux, Google Firebase, Heroku, and Git and Github as a version control system. I have strong analytical knowledge that I regularly put into practice through testing in software development. My strong capabilities with visually stunning web pages make me a valuable team member. I also possess strong interpersonal skills and readily interact with clients and co-workers to achieve desired results. I focus on time management to accomplish the task within the stipulated time.
            I strongly believe you will be impressed seeing the mentioned skill sets of any of your employees. I anticipate meeting with you soon to discuss my potential candidacy and what I have to offer. I have forwarded my resumes and websites..</p>
            <a href="https://rhasan346.medium.com/advanced-javascript-topics-5e51eaf6cb66">Read more</a>
             </div>
             </div>
             <div  className="jumbotron jumbotron-fluid">
            <div style={{border : '1px solid black'}} className="container mt-5 mb-5">
            <h3 style={{color : 'navy'}}>How to become a web developer ...</h3>
            <p className="lead">I am expressing my keen interest and I know I will be well-suited for the job. Last few months I have developed a few stunning and sophisticated websites for local start-ups as well as international exposure. I rely on extensive knowledge of MERN stacks along with Redux, Google Firebase, Heroku, and Git and Github as a version control system. I have strong analytical knowledge that I regularly put into practice through testing in software development. My strong capabilities with visually stunning web pages make me a valuable team member. I also possess strong interpersonal skills and readily interact with clients and co-workers to achieve desired results. I focus on time management to accomplish the task within the stipulated time.
            I strongly believe you will be impressed seeing the mentioned skill sets of any of your employees. I anticipate meeting with you soon to discuss my potential candidacy and what I have to offer. I have forwarded my resumes and websites..</p>
            <a href="https://rhasan346.medium.com/javascript-beginner-to-advance-76aebd921add">Read more</a>
             </div>
             </div>
            </div>
    );
};

export default Blogs;