import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "../navBar/NavBar";
import "./contactForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";

const ContactForm = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div data-aos="flip-down">
      <section className="contact ms-auto my-5 py-5">
        <div className="container">
          <div className="section-header text-center text-white mb-5">
            <h1>Leave Me a Message</h1>
          </div>
          <div className="col-md-9 mx-auto">
            <form action="https://formspree.io/f/mpzkqrrq" method="POST">
              <div className="form-group">
                <input
                  type="name"
                  name="_replyto"
                  className="form-control"
                  placeholder="Name *"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="_replyto"
                  className="form-control"
                  placeholder="Email Address *"
                />
              </div>
              <br />
              <div className="form-group">
                <textarea
                  name="message"
                  className="form-control"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Message *"
                ></textarea>
              </div>
              <br />
              <div className="form-group text-center">
                <button type="submit" className="btn btn-primary">
                  {" "}
                  Connect With Me{" "}
                </button>
                <h1>
                  {" "}
                  <a href="https://github.com/rafiulhc">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>{" "}
                </h1>
                <h1>
                  <a target="_blank" href="https://www.linkedin.com/in/hrafiul">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </h1>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
