import React from 'react';
import ContactForm from '../shared/contactForm/ContactForm';
import NavBar from '../shared/navBar/NavBar';
import Description from './description/Description';
import Header from './header/Header';
import Skill from './skill/Skill';
import SampleWork from './sampleWork/SampleWork'
import Loader from "react-loader-spinner";
const Home = () => {
    return (
            <div style={{backgroundColor : 'midnightblue'}}>
                {/* <Loader   
        type="Bars"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={1000} //3 secs
      />  */}
            <NavBar/>
            <Header/>
            <Skill/>
            <SampleWork/>
            <Description/>
            <ContactForm/>
        </div>
        
    );
};

export default Home;