import React from 'react';
import NavBar from '../shared/navBar/NavBar';
import Project from './project/Project';

const Projects = () => {
    return (
        <div>
            <NavBar/>
           <Project/>
        </div>
    );
};

export default Projects;