import { Grid } from '@material-ui/core';
import React from 'react';
import photo from '../../../image/circle.png'
import './header.css'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNodeJs, faReact } from '@fortawesome/free-brands-svg-icons';
import { faCloudDownloadAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import {Spring ,animated} from 'react-spring'
import Typewriter from 'typewriter-effect';
import Typical from 'react-typical'
import computer from '../../../image/computer.jpg'
import {motion} from 'framer-motion';

const Header = () => {
    return (
        <div className="container">
  <div className="row">
    <div className="col-md-6 col-sm-12 text-white" style={{height : '680px'}}>
    <h1  className="mt-5 ms-2 fst-italic">Hello ! I am Rafiul Hasan <br/>Aspirant<span style={{color : 'lawngreen'}}>Full-stack | Blockchain Developer</span> <br/> </h1><h1 style={{color : 'pink', fontSize : '35px'}}>
      <Typewriter 
    onInit ={(typewriter)=>{
      typewriter.typeString('Enthusiastic to explore more').pauseFor(2000).deleteAll().typeString('Consistent Learner').pauseFor(2000).deleteAll().typeString('Ready to join now').pauseFor(2000).start()
    }}
    /></h1>
    
   <motion.button
   initial={{x : '1200'}}
   animate={{x:0}}
   className="download-style mb-4"><a href="https://drive.google.com/file/d/17NyS6qGoXNHMRPRYd_ETpTWGjK03rEq-/view?usp=sharing">Get My Resume{' '} <FontAwesomeIcon icon={faCloudDownloadAlt} /></a></motion.button> 

  <img style={{width :'100%' ,borderRadius :'10px'}} src={computer}/>
    </div>
    <div className="col-md-6 col-sm-8 mt-5 text-white text-center" style={{height : '680px'}}>
      <h1 className="mt-5">Why Me</h1>
      <h3>Thirsty to adopt new tech <br/> Fast-learner, Open-minded
      <br/>Problem-solver <br/>Approachable and Helpful <br/>Creative Team-mate
      <br/>Leader and Mentor <br/>Agile, Critical-thinker</h3>
    </div>
  </div>
</div>
    );
};

export default Header;
