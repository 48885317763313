import React from "react";
import NavBar from "../shared/navBar/NavBar.js";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";

const Resume = () => {
  return (
    <div className="mb-5">
      <NavBar />
      <div style={{ marginLeft: "120px" }} className="mt-5 mb-3">
        <button className="download-style">
          <a href="https://drive.google.com/file/d/17NyS6qGoXNHMRPRYd_ETpTWGjK03rEq-/view?usp=sharing">
            Download Resume <FontAwesomeIcon icon={faCloudDownloadAlt} />
          </a>
        </button>
      </div>
      <div className="container">
        <div className="mt-5 mb-5">
          <h3>Rafiul Hasan Chowdhury</h3>
          <h4>Full-stack Blockchain Developer</h4>
        </div>
        <div>
          <h6>
            Email : rafiul.hasan.chowdhury@gmail.com
            <br />
            LinkedIn : https://www.linkedin.com/in/hrafiul/
            <br />
            GitHub : https://github.com/rafiulhc
          </h6>
        </div>
        <div>
          <p>
            Passionate about software architecture, armed with popular web
            technologies, focused on solutions in an organized manner.
            Prioritize the implementation of what I have learned in designed
            achievements. In my previous jobs, I was an important team member to
            launch software within the stipulated time, and it made me a
            responsible employee. Problem-solving is my life-long journey. I
            have a great challenge-taking personality which made me an integral
            part of any team.
            <br /> ability to creating solutions in the least amount of time.
            Developed web app <br />
            for a grocery management system, renovation service provider, and so
            on.
          </p>
          <h3>Skills</h3>
          <p>
            >> Vanilla JS, React.js, React-Router, Hooks, Context API, Redux
            <br />
            >> REST API , Node.js, Express.js , MongoDB , Google Firebase
            <br />
            >> Material UI, Bootstrap, CSS, SASS
            <br />
            >> Git and Github, Chrome Dev Tools, Netlify, Heroku. Payment
            Gateway Integration( Stripe)
            <br />
          </p>
        </div>
        <h3>Projects</h3>
        <p>❖ Online Order Management Service Website</p>
        <p>Features :</p>
        <p>
          Complete Sign-in functionality with Google Firebase extended with
          reset or forget the password, new customer registration.
          <br />
          Used REST API Node.js Express.js, MongoDB used in Backend.
          <br />
          Admin and users are separated by using JWT for authentication.
          <br />
          The best solution for the owner of retail shop those is willing to
          expand their business in local online-based customer.
        </p>
        <h6>Site Link: https://hill-view-mart-client.web.app/</h6>
        <p>
          The technology used: React React-Router, Hooks, Context API, Node,
          Express, MongoDB, Heroku, Google Firebase, Material UI
        </p>
        <p>❖ Home Renovation Service</p>
        <p>Features :</p>
        <p>
          Order Taking from nearby customers for a service-oriented company
          using the features of payment gateway like Stripe, SSL Commerze .
          <br />
          Admin can update or delete the type of services at any time using the
          admin dashboard.
          <br />
          The order tracking system checking the database in real-time.
          <br />
          Fast loading facility with the amazing power of React.js.
          <br />
          Context API and Hooks are used as easy to understand for future
          developers.
          <br />
        </p>
        <h6>Site Link: https://login-form-6274e.web.app/</h6>
        <p>
          The technology used: React React-Router, Hooks, Context API, Node,
          Express, MongoDB, Redux, Heroku, Google Firebase, Material UI.
        </p>
        <br />
        <p>❖Select Your Favourite Football Team</p>
        <h6>
          Site Link:
          https://6049be6e89a51449d3b33a49--blissful-benz-6afe87.netlify.app/
        </h6>
        <p>
          The technology used: React React-Router, Hooks, REST API, Node,
          Express, MongoDB, Redux, Netlify, Google Firebase, Material UI.
        </p>
        <br />
        <h3>Education</h3>
        <small>July 2009 - May 2015</small>
        <h6>Master of Science, National University-Bangladesh</h6>
        <br />
        <small>July 2019 - May 2020</small>
        <h6>Professional Course, Singapore Polytechnic-Singapore</h6>
      </div>
    </div>
  );
};

export default Resume;
