import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNodeJs, faReact } from '@fortawesome/free-brands-svg-icons';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { motion } from "framer-motion"

const imgContainer={
  
  height : '300px',
  borderRadius : '20px'

}
const Description = () => {
    return (
        
          <div className="container">
          <h1 className="text-white text-center mt-5 mb-5">My Weapons</h1>
          <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={4}>
            <motion.div
            style={{borderRadius : "5px"}}
            whileHover={{scale :1.1,
              boxShadow : ' 0px 0px 15px rgb(255 ,255 ,255)'
              }}
            >
            <h2 className="text-center mt-3 mb-5" style={{color: 'yellow'}}><FontAwesomeIcon icon={faReact} />{' '}FrontEnd </h2>
          <h5 className="text-center mt-3 mb-5" style={{color: 'white'}}>React, Redux, Next.js<br/> <br/> Calendar, React-form, Styled Component <br/>Next.js, jsPdf, React-to-Pdf, React-Select<br/> Ant Design,  Storybook,  SASS,<br/>  CSS3,  Bootstrap 4,  Tailwindcss</h5>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <motion.div
          style={{borderRadius : "5px"}}
          whileHover={{scale :1.1,
            boxShadow : ' 0px 0px 15px rgb(255 ,255 ,255)'
            }}
          >
          <h2 className="text-center mt-3 mb-5" style={{color: 'yellow'}}><FontAwesomeIcon icon={faNodeJs} />{' '}BackEnd </h2>
          <h5 className="text-center mt-3 mb-5" style={{color: 'white'}}>Node.js, Express.js, Django<br/>gRPC, Protocol Buffer, GraphQL, Webpack <br/><br/>AWS Cloud, Mongodb, Postgres, Docker, Kubernetes<br/></h5>
          </motion.div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <motion.div 
          style={{borderRadius : "5px"}}
          whileHover={{scale :1.1,
            boxShadow : ' 0px 0px 15px rgb(255 ,255 ,255)'
            }}
          >
          <h2 className="text-center mt-3 mb-5" style={{color: 'yellow'}}><FontAwesomeIcon icon={faTools} />{' '} Blockchain Skills</h2>
          <h5 className="text-center mt-3 mb-5" style={{color: 'white'}}><br/>Core Blockchain, EVM, Cosmos SDK, Tendermint Core, Openzeppelin, Smart Contracts, Dapps, DAO, NFT Marketplace</h5>
          </motion.div>
          </Grid>
          </Grid>
      </div>
        
        
    );
};

export default Description;