import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import photo from '../../../image/manage.png'
import juice from '../../../image/juice.png'
import order from '../../../image/order.png'
import review from '../../../image/review.png'
import chair from '../../../image/chair.png'
import home from '../../../image/home.png'
import nature from '../../../image/nature.png'
import travel from '../../../image/travel.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const imgStyle ={
        width : '90%'
            }
const Project = () => {
    useEffect(()=>{
        Aos.init({ duration : 1000})
      },[])
    return (
        <div className="mt-5 ms-5">
            <Grid container>
                
                <Grid data-aos="fade-right" className="mt-5 mb-5" item xs={10} lg={6}>
                <img style={imgStyle} src={travel}/>
                </Grid>
                <Grid data-aos="fade-left" className="mt-5 mb-5" item xs={10} lg={6}>
                <ul>
                    <li>
                    Complete Sign-in functionality with Google Firebase extended with reset or forget the password, new customer registration.
                    </li>
                    <li>REST API Node.js Express.js, MongoDB used in Backend.</li>
                    <li>Admin and users are separated by using JWT for authentication.</li>
                    <li>Travel booking app and tracking with Google Map on real-Tima</li>
                    <li>Tracking of booking history on real-time.</li>
                    <li>Payment method integreted with Stripe</li>
                </ul>
                <h4><a href="https://react-auth-rafiulhc.web.app/">Live Site</a></h4>
                <h4><a href="https://github.com/rafiulhc/flying-bee">Github</a></h4>
                <h5>Tech used: React, React-Router, Hooks, Context API, Google Map, MongoDB, Heroku, Google Firebase, Material UI</h5>
                </Grid>
                
                <Grid data-aos="fade-right" className="mt-5 mb-5" item xs={10} lg={6}>
                <ul>
                <li>Order Taking from nearby customers for a service-oriented company using the features of payment gateway like Stripe, SSL Commerze .</li>
                <li>Admin can update or delete the type of services at any time using the admin dashboard.</li>
                <li>The order tracking system checking the database in real-time.</li>
                <li>Fast loading facility with the amazing power of React.js.</li>
                <li>Context API and Hooks are used as easy to understand for future developers.</li>
                </ul>
                <h4><a href="https://login-form-6274e.web.app/">Live Site</a></h4>
                <h4><a href="https://github.com/rafiulhc/ocean-pearl-renova">Github FrontEnd</a></h4>
                <h4><a href="https://github.com/rafiulhc/ocean-pearl-renova-server">Github BackEnd</a></h4>
                <h5>Tech used: React, React-Router, Hooks, Context API, Node, Express, MongoDB, Heroku, Google Firebase Redux.js, Rest API</h5>
                </Grid>
                <Grid data-aos="fade-left" className="mt-5 mb-5" item xs={10} lg={6}>
                <img style={imgStyle} src={home}/>
                </Grid>
                <Grid item xs={10} lg={6}>
                <img style={imgStyle} src={juice}/>
                </Grid>
                <Grid data-aos="fade-right" className="mt-5 mb-5" item xs={10} lg={6}>
                <ul>
                <li>REST API Node.js Express.js, MongoDB used in Backend.</li>
                    <li>Admin and users are separated by using JWT for authentication.</li>
                    <li>The best solution for the owner of retail shop those is willing to expand their business in local online-based customer.</li>
                    <li>
                    Complete Sign-in functionality with Google Firebase extended with reset or forget the password, new customer registration.
                    </li>
                    
                </ul>
                <h4><a href="https://hill-view-mart-client.web.app/">Live Site</a></h4>
                <h4><a href="https://github.com/rafiulhc/juice-heaven-client">Github FrontEnd</a></h4>
                <h4><a href="https://github.com/rafiulhc/juice-heaven-server">Github BackEnd</a></h4>

                <h5>Tech used: React, React-Router, Hooks, Context API, Google Map, MongoDB, Heroku, Google Firebase, Material UI</h5>
                </Grid>
                <Grid data-aos="fade-left" className="mt-5 mb-5" item xs={10} lg={6}>
                <ul>
                    <li>
                    Complete Sign-in functionality with Google Firebase extended with reset or forget the password, new customer registration.
                    </li>
                    <li>REST API Node.js Express.js, MongoDB used in Backend.</li>
                    <li>Admin and users are separated by using JWT for authentication.</li>
                    <li>The best solution for the owner of retail shop those is willing to expand their business in local online-based customer.</li>
                </ul>
                <h4><a href="https://e-commerce-94a37.web.app">Live Site</a></h4>
                <h4><a href="https://github.com/rafiulhc/panda-commerce-simple">Github FrontEnd</a></h4>
                <h4><a href="https://github.com/rafiulhc/panda-commerce-server">Github BacktEnd</a></h4>
                </Grid>
                <Grid data-aos="fade-up" className="mt-5 mb-5" item xs={10} lg={6}>
                <img style={imgStyle} src={order}/>
                </Grid>
                
                <Grid data-aos="fade-up" className="mt-5 mb-5" item xs={10} lg={6}>
                <img style={imgStyle} src={nature}/>
                </Grid>
                <Grid className="mt-5 mb-5" item xs={10} lg={6}>
                <ul>
                    <li>
                    Complete Sign-in functionality with Google Firebase extended with reset or forget the password, new customer registration.
                    </li>
                    <li>REST API Node.js Express.js, MongoDB used in Backend.</li>
                    <li>Admin and users are separated by using JWT for authentication.</li>
                    <li>The best solution for the owner of retail shop those is willing to expand their business in local online-based customer.</li>
                </ul>
                <h4><a href="https://rafiulhc.github.io/slider-assignment/">Live Site</a></h4>
                <h4><a href="https://github.com/rafiulhc/slider-assignment">Github</a></h4>
                </Grid>
                </Grid>
        </div>
    );
};

export default Project;