import { Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faNodeJs, faReact } from '@fortawesome/free-brands-svg-icons';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import photo from '../../../image/manage.png'
import juice from '../../../image/juice.png'
import order from '../../../image/order.png'
import review from '../../../image/review.png'
import chair from '../../../image/chair.png'
import home from '../../../image/home.png'
import travel from '../../../image/travel.png'
import nature from '../../../image/nature.png'
import {Spring} from 'react-spring'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { motion } from "framer-motion"


const SampleWork = () => {
  useEffect(()=>{
    Aos.init({ duration : 1000})
  },[])
    return (
     
            <div  className="container">
          <h1 className="text-white text-center mt-5 mb-5">My Recent Works</h1>
          <Grid container spacing={6}>
          <Grid  data-aos="fade-right" item xs={12} md={6} lg={4}>
          <motion.div
           whileHover={{scale :1.1,boxShadow : ' 0px 0px 15px rgb(255 ,255 ,255)'}}
            class="card" style={{width: '18rem;'}}>
    <img  class="card-img-top" src={nature} alt="Card image cap"/>
    <div class="card-body">
      <h5 class="card-title">Name Registry Smart Contract</h5>
      <p class="card-text">Oracle Decentralized Name Registry on EVM Blockchain</p>
      <a target="_blank" href="https://github.com/rafiulhc/Name-Registry-Smart-Contract" class="btn btn-dark"><FontAwesomeIcon icon={faGithub} /></a>{' '}{' '}
      <a target="_blank" href="https://testnets.opensea.io/assets/rafiultoken"  class="btn btn-primary">Livesite</a>
    </div>
  </motion.div>
          </Grid>
          <Grid data-aos="flip-up" item xs={12} md={6} lg={4}>
          <motion.div
           whileHover={{scale :1.1,boxShadow : ' 0px 0px 15px rgb(255 ,255 ,255)'}}
            class="card" style={{width: '18rem;'}}>
  <img class="card-img-top" src={travel} alt="Card image cap"/>
  <div class="card-body">
    <h5 class="card-title">Crypto Investing App</h5>
    <p class="card-text">Growing Crypto Assets</p>
    <a target="_blank" href="https://github.com/rafiulhc/Crypto-investing-app" class="btn btn-dark"><FontAwesomeIcon icon={faGithub} /></a>{' '}{' '}
    <a target="_blank" href="https://minority-programmers-skiltest.web.app/home" class="btn btn-primary">Livesite</a>
  </div>
</motion.div>
          </Grid>
          <Grid data-aos="fade-left" item xs={12} md={6} lg={4}>
          <motion.div
           whileHover={{scale :1.1,boxShadow : ' 0px 0px 15px rgb(255 ,255 ,255)'}}
            class="card" style={{width: '18rem;'}}>
  <img class="card-img-top" src={home} alt="Card image cap"/>
  <div class="card-body">
    <h5 class="card-title">Decentralized Exchange</h5>
    <p class="card-text">Cryptoswap Platform</p>
    <a target="_blank" href="https://github.com/rafiulhc/crypto-staking-smart-contract" class="btn btn-dark"><FontAwesomeIcon icon={faGithub} /></a>{' '}{' '}
    <a target="_blank" href="https://www.bedrockswap.finance/" class="btn btn-primary">Livesite</a>
  </div>
</motion.div>
          </Grid>
          </Grid>
          <div>
              <h3 ><Link style={{textDecoration : 'none' ,color: 'white'}} to="/projects">Explore More about My works</Link></h3>
          </div>
      </div>
          
            
    );
};

export default SampleWork;