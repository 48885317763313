import logo from './logo.svg';
import './App.css';
import {  BrowserRouter as Router,  Switch,  Route,  Link} from "react-router-dom";
import Home from './components/home/Home';
import Resume from './components/resume/Resume';
import Blogs from './components/blogs/Blogs';
import Projects from './components/projects/Projects';
import Contact from './components/contact/Contact';
import ContactForm from './components/shared/contactForm/ContactForm';
import Header from './components/home/header/Header';



function App() {
  return (
    <div>
    <Router>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route exact path="/home">
            <Home/>
          </Route>
          <Route  path="/resume">
            <Resume/>
          </Route>
          <Route exact path="/blogs">
            <Blogs/>
          </Route>
          <Route exact path="/projects">
            <Projects/>
          </Route>
          <Route exact path="/contact">
            <Contact/>
          </Route>
        </Switch>
    </Router>
    </div>
  );
}

export default App;
