import React from 'react';
import RightAlignedTimeline from './timeLine/RightAlignedTimeline';


const Skill = () => {
    return (
        <div className="container">

  <div className="row">
    <div className="col-md-6 col-sm-12 text-white mt-5 " style={{height : '680px'}}>
     <h1  className="mt-5 ms-1 fst-italic">I am Full-stack | Blockchain Developer </h1>
     <br/>
     <p className="mt-5"> I rely on extensive knowledge of Blockchain technology and Full-stack. I have strong analytical knowledge that I regularly put into practice through testing in software development. My strong capabilities with visually stunning web pages make me a valuable team member. I also possess strong interpersonal skills and readily interact with clients and co-workers to achieve desired results. I focus on time management to accomplish the task within the stipulated time. Last few months I have developed a few stunning and sophisticated websites for local start-ups as well as international exposure.
      </p>
    </div>
    <div className="col-md-6 col-sm-12" style={{height : '680px'}}>
      <h1 style={{marginLeft : '200px'}} className="text-dark mt-5 mb-5">Languages</h1>
      <RightAlignedTimeline/>
    </div>
  </div>
</div>
    );
};

export default Skill;