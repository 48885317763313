import React from 'react';
import ContactForm from '../shared/contactForm/ContactForm';
import NavBar from '../shared/navBar/NavBar';


const Contact = () => {
    return (
        <div>
            <NavBar/> 
            <ContactForm/>     
        </div>
    );
};

export default Contact;